import { createSlice , createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import checkErrorsFunctions from "../../../jsx/global-functions/checkErrorsFunction";
import { checkErrors } from "../../../jsx/global-functions/checkErrors";
export const getBasicSettingsFunc = createAsyncThunk(
    "basicSettings/get",
    async (api) => {
        try {
            const response = await axios.get(api );
            checkErrorsFunctions(response , response.data.errors);
            console.log(response)
            return response.data.data;
        }
        catch (error) {
            checkErrors(error.response , error.message);
        }
        
    }
)

const getBasicSettingsSlicer = createSlice({
    name : "basicSettings/get",
    initialState : {
        loading : null,
        data : null,
        error : null
    },
    reducer : {},
    extraReducers : (builder) => {
        builder.addCase(getBasicSettingsFunc.pending , (state) => {
            state.loading = true
            state.data = null
            state.error = null
        })
        builder.addCase(getBasicSettingsFunc.rejected , (state) => {
            state.loading = null
            state.data = null
            state.error = true
        })
        builder.addCase(getBasicSettingsFunc.fulfilled , (state , action) => {
            state.loading = false
            state.data = action.payload
            state.error = null
        })
    }
})

export default getBasicSettingsSlicer.reducer;